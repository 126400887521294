<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mt-2">
      <v-flex xs12 sm6 md4 lg4 class="text-right">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search logs')"
        ></v-text-field> </v-flex
      ><v-spacer></v-spacer>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="filterOwners"
        >
          <template v-slot:[`item.serial`]="{ item }">
            <v-avatar size="25" :color="$store.state.primaryColor">
              <span class="caption white--text">{{ item.serial }}</span>
            </v-avatar>
          </template>

          <template v-slot:[`item.userId`]="{ item }">
            <span class="caption">{{ item.userId }}</span>
          </template>

          <template v-slot:[`item.access`]="{ item }">
            <span class="caption">{{ item.access }}</span>
          </template>

          <template v-slot:[`item.dateTimeIn`]="{ item }">
            <span class="caption">{{ DateFormat(item.dateTimeIn) }}</span>
          </template>

          <template v-slot:[`item.dateTimeOut`]="{ item }">
            <span class="caption">{{ DateFormat(item.dateTimeOut) }}</span>
          </template>

          <template v-slot:[`item.action`]>
            <v-icon
              v-for="button in icons"
              :key="button.icon"
              small
              class="mx-1"
              style="cursor: pointer"
              @click="goTo(button.route)"
              >{{ button.icon }}</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      color="success lighten-5 success--text text--darken-3"
    >
      <span>{{ $t("access right updated") }}</span>

      <v-btn icon color="success darken-3" @click="snackSuccess = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";
export default {
  data: () => ({
    logs: [],
    loading: true,
    snackloading: false,
    search: "",
    serial: 0,

    icons: [{ icon: "visibility", route: "" }],

    snackSuccess: false,
  }),

  computed: {
    filterOwners() {
      return this.logs.filter((val) => {
        return (
          val.userId.toLowerCase().includes(this.search.toLowerCase()) ||
          String(val.serial).includes(this.search) ||
          val.access.toLowerCase().includes(this.search.toLowerCase()) ||
          this.DateFormat(val.dateTimeIn)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          this.DateFormat(val.dateTimeOut)
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      });
    },
    headers() {
      return [
        {
          text: "#",
          value: "serial",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("username"),
          value: "userId",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("access level"),
          value: "access",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("logged in"),
          value: "dateTimeIn",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("logged out"),
          value: "dateTimeOut",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-light",
        },
      ];
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      this.logs = [];
      this.serial = 1;
      db.collection("logs")
        .orderBy("dateTimeIn", "desc")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            db.collection("users")
              .doc(snaps.data().username)
              .get()
              .then((doc) => {
                this.logs.push({
                  id: snaps.id,
                  serial: this.serial++,
                  userId: doc.data().username,
                  access: doc.data().isSuperAdmin
                    ? "Root Access"
                    : doc.data().isOwner
                    ? "Owner"
                    : doc.data().isManager
                    ? "Manager"
                    : "Employee",
                  ...snaps.data(),
                });
              });
          });
          this.loading = false;
        });
    },

    DateFormat(data) {
      return data != null
        ? format(data.toDate(), "EEE, dd MMMM, yyyy hh:mm")
        : "";
    },
  },
};
</script>

<style></style>
