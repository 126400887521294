var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mt-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","sm6":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search logs')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filterOwners},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"25","color":_vm.$store.state.primaryColor}},[_c('span',{staticClass:"caption white--text"},[_vm._v(_vm._s(item.serial))])])]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.userId))])]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.access))])]}},{key:"item.dateTimeIn",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DateFormat(item.dateTimeIn)))])]}},{key:"item.dateTimeOut",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DateFormat(item.dateTimeOut)))])]}},{key:"item.action",fn:function(){return _vm._l((_vm.icons),function(button){return _c('v-icon',{key:button.icon,staticClass:"mx-1",staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.goTo(button.route)}}},[_vm._v(_vm._s(button.icon))])})},proxy:true}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"bottom":"","color":"success lighten-5 success--text text--darken-3"},model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("access right updated")))]),_c('v-btn',{attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }